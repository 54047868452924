* {
	box-sizing: border-box;
}

body {
	margin: 0;
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 3rem;
}

.chart {
	width: 100%;
	max-width: 38em;
}

h2 {
	font-weight: normal;
	font-family: sans-serif;
	font-size: 1.5em;
	margin: 0.25em;
}

.recharts-wrapper p {  
	color: initial;    
	font-size: initial;
}                      


.menu {
	overflow: hidden;
	background-color: #333;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99;
}

.submenu {
	background-color: #666;
	max-width: 40em;
	margin: 0 auto;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
}

.submenu h2 {
	color: white;
}

.submenu-header {
	display: flex;
	justify-content: space-between;
}

.menu-container {
	max-width: 40em;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.menu button {
	background-color: #333;
	height: 2.5rem;
	min-width: 3rem;
	font-size: 1.5rem;
	color: white;
	border-radius: 0;
	border: 0;
}

.menu button:hover {
	background-color: #999;
}

.menu button.active {
	background-color: #666;
}

.submenu button {
	background-color: #666;
}

.datepicker .rdtPicker {
	background: none;
	border: none;
	color: white;
	font-size: 1rem;
	font-family: sans-serif;
}

.datepicker th:hover,
.datepicker td:hover {
	background-color: #999!important;
}

